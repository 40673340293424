import { DefaultButton, PrimaryButton, Text } from '@fluentui/react';
import soundTest from '../../assets/soundTest.mp3';
import React, { useState } from 'react';
import AudioAnimation from './AudioAnimation';

interface SpeakerCheckProps {
  soundTested: boolean;
  onSoundTest: (result: boolean) => void;
  onTrackEvent: (name: string, eventMessage: string, properties?: any) => {};
  speakerDeviceId: string | undefined;
}

const SpeakerCheck: React.FC<SpeakerCheckProps> = ({ soundTested, onSoundTest, onTrackEvent, speakerDeviceId }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [audio, setAudio] = useState(null as HTMLAudioElement | null);
  const [hasPlayed, setHasPlayed] = useState(false);




  const playTestSound = async () => {
    const newAudio = new Audio(soundTest)
    if(speakerDeviceId){
      (newAudio as any).setSinkId(speakerDeviceId);
    }
    newAudio.loop = true;
    newAudio.volume = .3;
    await newAudio.play().catch(() => {
      completeSoundTest(false);
    });
    onTrackEvent("deviceCheckSpeakerPlayTestSound", `Playing test sound`);
    setIsPlaying(true);
    setHasPlayed(true);
    setAudio(newAudio);
  };

  const completeSoundTest = (result: boolean) => {
    stopTestSound();
    onSoundTest(true);
  };


  const stopTestSound = () => {
    setIsPlaying(false);
    if (audio) {
      audio.loop = false;
      audio.pause();
      audio.currentTime = 0;
    }
  };

  return (
    <>

      <>
        <div style={{textAlign: 'left', fontSize: 14, fontWeight: 500}}>Click "Play Test Sound" to continue...</div>
        <div style={{textAlign: 'left', fontSize: 14, fontWeight: 500}}>If you are having trouble, be sure to check your bluetooth settings.</div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: '10px' }}> {/* Container for the fancy play button */}
            <DefaultButton onClick={isPlaying ? stopTestSound : playTestSound} iconProps={{ iconName: isPlaying? 'Pause' : 'Play' }} text="Play Test Sound" />
          </div>
          <AudioAnimation animate={isPlaying} />
        </div>
        <Text variant={'medium'} style={{ textAlign: 'left', fontSize: 14, fontWeight: 500, minHeight: 30}}>
          {isPlaying && 'A test sound is playing, can you hear it?'}
          </Text>
        <PrimaryButton  className="thButton" onClick={() =>{onTrackEvent("deviceCheckSpeakerICanHear", `Pressed Yes, I can hear it`); completeSoundTest(true);}} text="Yes, I can hear it" disabled={!(hasPlayed)} />
        <DefaultButton  className="thButton secondary" onClick={() =>{onTrackEvent("deviceCheckSpeakerHearingImpaired", `Pressed I am hearing impaired`); completeSoundTest(true);}} text="I am hearing impaired" disabled={!(!soundTested)} />
      </>
    </>
  );
};

export default SpeakerCheck;
