export interface Tip {
  message: string;
}

export const tips: Tip[] = [
  { message: "Ensure your devices are plugged in or fully charged to avoid interruptions." },
  { message: "Click 'Check my devices' to ensure you can be seen and heard during your call." },
  { message: "Position yourself in a well-lit area so your healthcare provider can see you clearly." },
  { message: "Be in a quiet, private space to maintain confidentiality." },
  { message: "Close unnecessary applications or browser tabs to improve call performance." },
  { message: "Inform others around you that you're on a call to minimize interruptions." },
  { message: "Have important medical documents or information handy." },
  { message: "Ensure your microphone is not muted." },
  { message: "Speak clearly and directly into the microphone." },
  { message: "Mute notifications on your device to avoid distractions." },
  { message: "If helpful for you, have a pen and paper ready to take notes during the call." },
  { message: "Wear clothing that allows you to show any areas of concern if needed." },
  { message: "Have your proof of identity (driver's license or state ID) and health insurance card (if you're using insurance) with you for your visit." },
  { message: "Prepare to discuss your symptoms and any recent changes in your health." },
  { message: "Stay focused on the appointment and avoid multitasking during the call." },
  { message: "If possible, sit close to your Wi-Fi router to ensure a stable connection." },
  { message: "Let your provider know if you're experiencing any technical issues." },
  { message: "If the visit is for a minor, please ensure the parent/guardian are also present." }
];