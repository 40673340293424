import React from 'react';
import { Dropdown, IDropdownOption } from '@fluentui/react';

interface DeviceSelectionProps {
  devices: { cameras: MediaDeviceInfo[], microphones: MediaDeviceInfo[], speakers: MediaDeviceInfo[] };
  selectedCamera: string | undefined;
  selectedMicrophone: string | undefined;
  selectedSpeaker: string | undefined;
  showOneDevice?: 'camera' | 'microphone' | 'speaker' | undefined;
  onDeviceChange: (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, type?: 'camera' | 'microphone' | 'speaker') => void;
}

const DeviceSelection: React.FC<DeviceSelectionProps> = ({ devices, selectedCamera, selectedMicrophone, selectedSpeaker, showOneDevice, onDeviceChange }) => {
  return (
    <div>
      {devices.cameras.length > 1 && (showOneDevice === undefined || showOneDevice == 'camera') && (
        <div style={{ width: '100%', textAlign:'left' }}>
          
          <div style={{ width:'100%', fontWeight:500, fontSize:14, padding:0, marginBottom: 10 }}>Select a Camera</div>
          <div style={{ }}>
            <Dropdown
              options={devices.cameras.map(camera => ({ key: camera.deviceId, text: camera.label }))}
              selectedKey={selectedCamera}
              onChange={(e, option) => onDeviceChange(e, option, 'camera')}
              placeholder="Select a camera"
              style={{ width: '100%', borderColor: 'darkgrey', borderRadius: 10 }}
            />
          </div>
        </div>
      )}
      {devices.microphones.length > 1 && (showOneDevice === undefined || showOneDevice == 'microphone') && (
        <div style={{ width: '100%', textAlign:'left' }}>
          
        <div style={{ width:'100%', fontWeight:500, fontSize:14, padding:0, marginBottom: 10 }}>Select a Microphone</div>
        <div style={{ }}>
            <Dropdown
              options={devices.microphones.map(microphone => ({ key: microphone.deviceId, text: microphone.label }))}
              selectedKey={selectedMicrophone}
              onChange={(e, option) => onDeviceChange(e, option, 'microphone')}
              placeholder="Select a microphone"
              style={{ width: '100%', borderColor: 'darkgrey', borderRadius: 10 }}
            />
          </div>
        </div>
      )}
      {devices.speakers.length > 1 && (showOneDevice === undefined || showOneDevice == 'speaker') && (
        <div style={{ width: '100%', textAlign:'left' }}>
          
        <div style={{ width:'100%', fontWeight:500, fontSize:14, padding:0, marginBottom: 10 }}>Select a Speaker</div>
          <div style={{ }}>
              <Dropdown
                options={devices.speakers.map(speaker => ({ key: speaker.deviceId, text: speaker.label }))}
                selectedKey={selectedSpeaker}
                onChange={(e, option) => onDeviceChange(e, option, 'speaker')}
                placeholder="Select a speaker"
                style={{ width: '100%', borderColor: 'darkgrey', borderRadius: 10 }}
              />
          </div>
        </div>
      )}
    </div>
  );
};

export default DeviceSelection;
