import React, { useEffect, useState } from 'react';
import { Text, PrimaryButton, DefaultButton } from '@fluentui/react';

interface MicrophoneCheckProps {
  onMicCheckPass: (passed: boolean) => void;
  onTrackEvent: (name: string, eventMessage: string, properties?: any) => {};
  audioStream: MediaStream | null;
  micAccess: boolean;
}

const soundBarStyles: React.CSSProperties = {
  width: '100%',
  height: '20px',
  backgroundColor: '#e0e0e0',
  position: 'relative' as 'relative' | undefined,
  borderRadius: '4px',
  overflow: 'hidden',
};

const soundLevelStyles = {
  height: '100%',
  backgroundColor: '#4caf50',
  transition: 'width 0.1s linear'
};



const MicrophoneCheck: React.FC<MicrophoneCheckProps> = ({ onMicCheckPass, onTrackEvent, audioStream, micAccess }) => {
  const [audioLevel, setAudioLevel] = useState(0);
  const [animationFrameId, setAnimationFrameId] = useState(0);
  const [micVolumePassed, setMicVolumePassed] = useState(false);

  const runAudioAnalysis = () => {
    // Set up audio level monitoring
    if (audioStream !== null) {
      const audioContext = new (window.AudioContext || window.AudioContext)();
      const microphone = audioContext.createMediaStreamSource(audioStream);
      const analyser = audioContext.createAnalyser();
      microphone.connect(analyser);
      analyser.fftSize = 256;
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      const getAudioLevel = () => {
        analyser.getByteFrequencyData(dataArray);
        const sum = dataArray.reduce((a, b) => a + b, 0);
        setAudioLevel(sum / bufferLength);
        if (sum / bufferLength > 30) {
          setMicVolumePassed(true);
        }
        setAnimationFrameId(requestAnimationFrame(getAudioLevel));
      };

      getAudioLevel();

    }


  }

  useEffect(() => {
    if (animationFrameId !== 0) {
      cancelAnimationFrame(animationFrameId);
      setAnimationFrameId(0);
    }
    runAudioAnalysis();
  }, [audioStream]);
/*
  useEffect(() => {
    if (!micVolumePassed) {
      const timer = setTimeout(() => {
        if (audioLevel > 0.3) {
          setMicVolumePassed(true);
        }
      }, 100);

      return () => clearTimeout(timer);
    }

    // Add a return statement here
    return () => { return null; };
  }, [micVolumePassed, audioLevel, setMicVolumePassed]);
*/
  return (
    <>
      {micAccess ?
        <>
          <div style={{textAlign: 'left', fontSize: 14, fontWeight: 500, marginBottom: -10}}>Please speak into the microphone.</div>
        <div style={{textAlign: 'left', fontSize: 14, fontWeight: 500, marginBottom: -10}}>If you are having trouble, be sure to check your bluetooth settings.</div>
          {(
            <div style={soundBarStyles}>
              <div style={{ ...soundLevelStyles, width: `${audioLevel}%` }}></div>
            </div>
          )}
          <div style={{ height: '40px' }}>
            {micVolumePassed ? (
              <div style={{textAlign: 'left', fontSize: 14, fontWeight: 500, marginBottom: -10}}>
                Microphone Check Passed
                <svg xmlns="http://www.w3.org/2000/svg"viewBox="0 0 24 24" width="24" height="24" style={{position: 'relative', top: 7, left: 5}}>
                  <circle cx="12" cy="12" r="10" stroke="#4caf50" stroke-width="2" fill="none" />
                  <path d="M9 12l2 2l4-4" stroke="#4caf50" stroke-width="2" fill="none" />
                </svg>
              </div>
            ) : (
              <Text>No sound detected. Please speak louder or move the microphone closer.</Text>
            )}
          </div>
        </>
        :
        <>
          <Text>No microphone detected. Please use another device or confirm that you communicate with ASL.</Text>
        </>
      }
        <>
          <PrimaryButton  className="thButton" onClick={() => { onTrackEvent("deviceCheckMicContinue", `Pressed Continue on Mic Check`); onMicCheckPass(true);}} text="Continue" disabled={!(micVolumePassed)} />
          <DefaultButton  className="thButton secondary" onClick={() => {onTrackEvent("deviceCheckMicASL", `Pressed I communicate with ASL`);  onMicCheckPass(true);}} text="I communicate with ASL" />
        </>
    </>
  );
};

export default MicrophoneCheck;
